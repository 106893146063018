@font-face {
  font-family: SharpSans Medium;
  src: url("SharpSans-Medium.4a83b05c.woff") format("woff"), url("SharpSans-Medium.c0a67e0a.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SharpSans Semibold;
  src: url("SharpSans-Semibold.b6f33015.woff") format("woff"), url("SharpSans-Semibold.fdcb9f3f.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SharpSans Bold;
  src: url("SharpSans-Bold.d694f752.woff") format("woff"), url("SharpSans-Bold.d40c804a.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SharpSans Light;
  src: url("SharpSans-Light.7f9bfcc0.woff") format("woff"), url("SharpSans-Light.cd737036.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SharpSans Book;
  src: url("SharpSans-Book.aff73fb8.woff") format("woff"), url("SharpSans-Book.a2f0f059.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

/*# sourceMappingURL=index.5f505848.css.map */
