@font-face {
    font-family: 'SharpSans Medium';
    src:
        url('./fonts/SharpSans-Medium.woff') format('woff'),
        url('./fonts/SharpSans-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SharpSans Semibold';
    src:
        url('./fonts/SharpSans-Semibold.woff') format('woff'),
        url('./fonts/SharpSans-Semibold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SharpSans Bold';
    src:
        url('./fonts/SharpSans-Bold.woff') format('woff'),
        url('./fonts/SharpSans-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SharpSans Light';
    src:
        url('./fonts/SharpSans-Light.woff') format('woff'),
        url('./fonts/SharpSans-Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SharpSans Book';
    src:
        url('./fonts/SharpSans-Book.woff') format('woff'),
        url('./fonts/SharpSans-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
